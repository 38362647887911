.select__control {
  height: 3.5rem !important;
  width: 100% !important;
  font-size: 1.125rem !important;
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
  border: 1px solid #d8e3e3 !important;
  border-radius: 32px !important;
  outline: 0 !important;
  box-shadow: none !important;
}
.select__control .select__indicator .select__loading-indicator {
  display: none !important;
}
.select__control.select__control--is-focused {
  border-color: #223b3a !important;
}
.select__control .select__value-container {
  padding: 0px !important;
}
.select__control .select__value-container .select__placeholder,
.select__control .select__value-container .select__input-container {
  margin: 0px !important;
  padding: 0px !important;
  font-size: 1.125rem !important;
}
.select__control .select__single-value {
  font-size: 1.2rem !important;
}

.select__loading-indicator {
  display: none !important;
}

.select__menu {
  z-index: 2;
  padding: 2px 0;
}
.select__menu .select__menu-list {
  padding: 6px 0;
}
.select__menu .select__menu-list .select-option-item {
  padding: 5px 14px;
  cursor: pointer;
  font-size: 1.2rem !important;
}
.select__menu .select__menu-list .select-option-item .option-label {
  font-size: 1.2rem !important;
}
.select__menu .select__menu-list .select-option-item:hover {
  background-color: #f5f7f8;
}

.main-search .select__control {
  height: 4rem !important;
}
.main-search .select__control .select__placeholder {
  font-size: 1.4rem !important;
}
.main-search .select__control .select__indicators {
  opacity: 0 !important;
}
.main-search .select__menu {
  z-index: 2 !important;
  padding: 2px 0 !important;
  border-radius: 10px !important;
}
.main-search .select__menu .select__menu-list {
  padding: 4px 0 !important;
}
.main-search .select__menu .select__menu-list .select-option-item {
  padding: 8px 17px !important;
  border-bottom: 1px solid #ddd;
}
.main-search .select__menu .select__menu-list .select-option-item:last-child {
  border-bottom: none;
}
.main-search .select__menu .select__menu-list .select-option-item .option-label {
  font-size: 1.15rem !important;
  font-family: "area-normal", sans-serif !important;
  font-weight: 700 !important;
}
.main-search .select__menu .select__menu-list .select-option-item .option-sublabel {
  font-family: "area-normal", sans-serif !important;
  color: #91b1b1 !important;
  font-size: 0.9rem !important;
  font-weight: 700 !important;
}